/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-button {
  text-transform: none;
}

.intro_footer {
  background-color: var(--ion-color-black);
  padding: 5px 20px;
  text-align: center;
}
.whitetext {
  color: white;
}
.bottomtext {
  font-size: 14px;
}
.header_tool {
  --background: var(--ion-color-black);
}

.largeText {
  font-size: 50px;
}
/*
.bottom-sheet-popover .popover-content {
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  top: calc(100% - 200px) !important;
  --height: 200px;
  background: white !important;
}
.bottom-sheet-order .popover-content {
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  top: calc(100% - 400px) !important;
  --height: 400px;
  background: white !important;
}
.bottomdetail-sheet-popover .popover-content {
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  top: calc(100% - 150px) !important;
  --height: 150px;
  background: white !important;
}
*/
/*
.action_dlg {
  --min-width: 500px;
}
*/
ion-menu-button {
  color: white !important;
}